export const view = require("../assets/view_.png");
export const close = require("../assets/close.png");
export const logo = require("../assets/logo.png");
export const goup = require("../assets/goup.png");
export const twitter = require("../assets/twitter.png");
export const insta = require("../assets/insta.png");
export const drop = require("../assets/drop.png");
export const button_ = require("../assets/button_.png");
export const bg = require("../assets/bg.png");
export const bg2 = require("../assets/background_1.png");
export const bg3 = require("../assets/background_2.png");
export const bg4 = require("../assets/background_3.png");
export const business = require("../assets/image4.jpg");
export const individual = require("../assets/image5.jpg");
export const coaches = require("../assets/image6.jpg");
export const fb = require("../assets/fb.png");
export const linkedin = require("../assets/linkedin.png");
export const footerLogo = require("../assets/footerLogo.png");
export const contactUs = require("../assets/contactUs.jpg");
export const loginG = require("../assets/loginsvg.svg");
export const coachyLogo = require("../assets/logocoachy.svg");
export const landing1 = require("../assets/landing1.jpeg");
export const pline = require("../assets/pline.jpeg");
export const landing2 = require("../assets/landing2.jpeg");
export const imgss = require("../assets/landiSc.svg");
export const map = require("../assets/mapImg.svg");
export const imgl1 = require("../assets/certificationImage.png");
export const imgl2 = require("../assets/experienceImage.png");
export const imgl3 = require("../assets/digitalImage.png");
export const labdu = require("../assets/labdu.jpeg");
export const EmailSuccessIcon = require("../assets/contactlOgo.svg");
export const coachBg = require("../assets/coachBg.jpg");
export const togetherHelp = require("../assets/togetherHelp2.png");
export const togetherHelpMobile = require("../assets/togetherHelpMobile.png");
export const whiteLogo = require("../assets/logo.png");
export const loginPurple = require("../assets/loginPurple.png");
export const loginWhite = require("../assets/loginWhite.png");
export const coachLandingVideo = require("../assets/arabsstock_V114605_4K.mp4");
export const secondScreen = require("../assets/carousel-2.png"); 
export const thirdScreen = require("../assets/carousel-3.png");
export const firstScreen = require("../assets/carousel-1.png");
export const leftArrow = require("../assets/leftArrow.png");
export const rightArrow = require("../assets/rightArrow.png");
export const landingPageVideo = require("../assets/arabsstock_V117832_4K.mp4");
export const orangeLeftArrow = require("../assets/orangeLeftArrow.png");
export const orangeRightArrow = require("../assets/orangeRightArrow.png");
export const loginButton = require("../assets/loginButton.svg");
export const qrcode = require("../assets/qrcode.png");
export const blurImage = require("../assets/blur-box.png");
export const blurImage2 = require("../assets/blur-box-1.png");
export const blurImage3 = require("../assets/blur-box-2.png");
export const contactBackgroundImage = require("../assets/contactBackgroundImage.png");
export const purposeImage = require("../assets/Purpose.jpg")
export const promiseImage = require("../assets/Promise.jpg")
export const Middle = require("../assets/Middle.jpg")
export const bottomLeft = require("../assets/bottomLeft.png")
export const BottomRight = require("../assets/BottomRight.jpg")