// Customizable Area Start
import React, { Component, createRef } from 'react';

interface ChunkedVideoPlayerProps {
    src: string;
    className?: string;
    controls?: boolean;
    style?: React.CSSProperties;
}

interface VideoChunkResponse {
    buffer: ArrayBuffer;
    length: number;
}

class ChunkedVideoPlayer extends Component<ChunkedVideoPlayerProps> {
    videoRef = createRef<HTMLVideoElement>();

    componentDidMount() {
        this.loadVideoChunks(this.props.src);
    }

    componentDidUpdate(prevProps: ChunkedVideoPlayerProps) {
        if (prevProps.src !== this.props.src) {
            this.loadVideoChunks(this.props.src);
        }
    }

    async fetchVideo(src: string): Promise<Response> {
        return await fetch(src);
    }

    async processChunk(reader: ReadableStreamDefaultReader<Uint8Array>): Promise<VideoChunkResponse | null> {
        const { done, value } = await reader.read();

        if (done || !value || !value.buffer) {
            return null;
        }

        return {
            buffer: value.buffer,
            length: value.length
        };
    }

    createVideoSource(chunks: ArrayBuffer[]): string {
        const blob = new Blob(chunks, { type: 'video/mp4' });
        return URL.createObjectURL(blob);
    }

    loadVideoChunks = async (src: string) => {
        const chunks: ArrayBuffer[] = [];

        try {
            const response = await this.fetchVideo(src);
            const reader = response.body?.getReader();

            if (!reader) {
                throw new Error('Unable to stream video');
            }

            while (true) {
                const chunk = await this.processChunk(reader);
                if (!chunk) break;

                chunks.push(chunk.buffer);
            }

            if (this.videoRef.current) {
                const videoUrl = this.createVideoSource(chunks);
                this.videoRef.current.src = videoUrl;
            }
        } catch (error) {
            console.error('Video loading error:', error);
        }
    };

    render() {
        const { className, controls, ...videoProps } = this.props;
        return (
            <video
                className={className}
                controls={controls}
                autoPlay
                muted
                loop
                ref={this.videoRef}
                {...videoProps}
            />
        );
    }
}

export default ChunkedVideoPlayer;
// Customizable Area End